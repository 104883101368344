// RadialChart taken from https://slides.com/shirleywu/deck-11/#/1
import React, { Component } from "react";
//import * as d3 from "d3";
import pym from "pym.js";
import { Auth } from "aws-amplify";


class Chart extends Component {

  async componentDidMount() {
      let { idToken: { jwtToken } } = await Auth.currentSession();
      const pymParent = new pym.Parent("chart", `netvis/child_1.html?jwt=${jwtToken}`, {});
  }

  render() {
    return <div id="chart"></div>;
  }
}

export default Chart;
