import React from "react";

function UserPolicy() {
  return (
    <article className="policy">
      <h1 className="c0">User Policy</h1>
      <p className="c0">
        <span className="c1">Last updated: June 10, 2020</span>
      </p>
      <p className="c0">
        <span className="c1">
          Please read this User Policy carefully before using this Website.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Interpretation</span>
      </h3>
      <p className="c0">
        <span className="c1">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Definitions</span>
      </h3>
      <p className="c0">
        <span className="c1">For the purposes of this User Policy:</span>
      </p>
      <ul className="c2 lst-kix_list_10-0 start">
        <li className="c5">
          <span className="c6">Affiliate</span>
          <span className="c1">
            &nbsp;means an entity that controls, is controlled by or is under
            common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </span>
        </li>
        <li className="c4">
          <span className="c6">California Energy Commission</span>
          <span className="c1">
            &nbsp;(also referred as &ldquo;CEC&rdquo;) is the entity providing
            support through the Prime Contractor to the Company for the
            Analyzer.
          </span>
        </li>
        <li className="c4">
          <span className="c6">Company</span>
          <span className="c1">
            &nbsp;(referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to iCatalysts LLC, 122 Redwood Road, San Anselmo, California,
            94960
          </span>
        </li>
        <li className="c4">
          <span className="c6">Country</span>
          <span className="c1">&nbsp;refers to: United States</span>
        </li>
        <li className="c4">
          <span className="c6">Device</span>
          <span className="c1">
            &nbsp;means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </span>
        </li>
        <li className="c4">
          <span className="c6">Prime Contractor</span>
          <span className="c1">
            &nbsp;refers to Industrial Economics (prime Contractor for CEC).
          </span>
        </li>
        <li className="c4">
          <span className="c6">Service</span>
          <span className="c1">
            &nbsp;refers to the Website or Innovation Network Analyzer.
          </span>
        </li>
        <li className="c4">
          <span className="c6">User Policy</span>
          <span className="c1">
            &nbsp;(also referred as &quot;Terms&quot;) mean these Terms and
            Conditions that form the entire agreement between you and the
            Company regarding the use of the website. This agreement is
            maintained by the Company.
          </span>
        </li>
        <li className="c4">
          <span className="c6">Website</span>
          <span className="c1">
            &nbsp;refers to Innovation Network Analyzer, accessible from
            https://networkanalyzer.icatalysts.net/
          </span>
        </li>
        <li className="c3">
          <span className="c6">You</span>
          <span className="c1">
            &nbsp;means the individual accessing or using the Website, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Website, as applicable.
          </span>
        </li>
      </ul>
      <h3 className="c0">
        <span className="c7">Acknowledgement</span>
      </h3>
      <p className="c0">
        <span className="c1">
          These are the Terms and Conditions of the User Policy governing the
          use of this website and the agreement that operates between you and
          the Company. These Terms and Conditions set out the rights and
          obligations of all users regarding the use of the Website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Your access to and use of the Website is conditioned on your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all visitors, users and others who
          access or use the Website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          <br />
          By accessing or using the website you agree to be bound by these Terms
          and Conditions. If you disagree with any part of these Terms and
          Conditions, then you may not access the website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          You represent that you are over the age of 18. The Company does not
          permit those under 18 to use the website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Your access to and use of the website is also conditioned on your
          acceptance of and compliance with the Privacy Policy of the Company.
          Our Privacy Policy describes our policies and procedures on the
          collection, use and disclosure of your personal information when you
          use the Application or the Website and tells you about your privacy
          rights and how the law protects you. Please read our Privacy Policy
          carefully before using our website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          This website is provided by ICatalysts under a Subcontract Agreement
          with the Prime Contractor which is the Prime Contractor for the
          California Energy Commission (&ldquo;Commission&rdquo;) . The
          California Energy Commission contributed funds to the Analyzer under
          California Energy Commission agreement number 300-17-004. The Energy
          Commission has certain rights to this Analyzer. The purpose of this
          Service is to provide a tool to analyze innovation networks of
          individuals and organizations that provide or use technologies that
          advance a clean economy. The Commission has the right to discontinue
          this Analyzer for their purpose at any time.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The Company does not warrant or make any representation regarding the
          use or the results of the use of the materials or information on the
          website, or the accuracy, adequacy, completeness, legality,
          reliability, or usefulness of any materials or information on the
          website. The Company does not evaluate or verify data used for this
          analysis. You must evaluate, and bear all risks associated with, the
          use of any content, including any reliance on the accuracy,
          completeness, or usefulness of such content.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Protection of Confidential Information</span>
      </h3>
      <p className="c0">
        <span className="c1">
          During the course of using this website, you (i) will not use or
          permit the use of Confidential Information in any manner or for any
          purpose not expressly set forth in this Agreement, (ii) will hold such
          Confidential Information in confidence and protect it from
          unauthorized use and disclosure, and (iii) will not disclose such
          Confidential Information to others except those in your organization
          who are authorized to view confidential information. You will protect
          Confidential Information from unauthorized use, access or disclosure
          in the same manner as you protect your own confidential information of
          a similar nature, but in no event will you &nbsp;exercise less than
          reasonable care in accessing the data on this website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          &ldquo;Confidential Information&rdquo; as used in this Agreement means
          all information available to you through this website that is not
          generally publicly known. This includes graphical or tabular data that
          identifies specific people or organizations and may be sensitive
          including connections between people or organizations or the strength
          of such connections. Other confidential information is financial data
          on specific organizations, technology readiness levels, and
          contractual data.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          You and your organization bear sole responsibility for your release of
          Confidential or sensitive data to persons who are not authorized to
          view it.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Confidential Information does not include information that is or
          becomes a part of the public domain through no act or omission of you.
          In addition, this section will not be construed to prohibit disclosure
          of Confidential Information to the extent that such disclosure is
          required by law or valid order of a court or other governmental
          authority; provided, however, that you will first have given notice to
          Company and will have made a reasonable effort to obtain a protective
          order requiring that the Confidential Information so disclosed be used
          only for the purposes for which the order was issued.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          You may be asked to sign a Non-Disclosure Agreement.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Links to Other Websites</span>
      </h3>
      <p className="c0">
        <span className="c1">
          Our website may contain links to third-party web sites or services
          that are not owned or controlled by the Company.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The Company has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third-party web
          sites or services. You further acknowledge and agree that neither the
          Company nor the Prime Contractor shall be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with the use of or reliance on any such
          content, goods or services available on or through any such web sites
          or services.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          <br />
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Termination</span>
      </h3>
      <p className="c0">
        <span className="c1">
          We may terminate or suspend your access immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach these Terms and Conditions.
          <br />
          Upon termination, your right to use the Service will cease
          immediately.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Limitation of Liability</span>
      </h3>
      <p className="c0">
        <span className="c1">
          Notwithstanding any damages that you might incur, the entire liability
          of the Company and the Prime Contractor or any of its suppliers under
          any provision of these Terms and your exclusive remedy for all of the
          foregoing shall be limited to the amount actually paid by you through
          the Service or 100 USD if you have not purchased anything through the
          Service.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          To the maximum extent permitted by applicable law, in no event shall
          the Company, the Prime Contractor or any of its suppliers be liable
          for any special, incidental, indirect, or consequential damages
          whatsoever (including, but not limited to, damages for loss of
          profits, loss of data or other information, for business interruption,
          for personal injury, loss of privacy arising out of or in any way
          related to the use of or inability to use the website, third-party
          software and/or third-party hardware used with the website, or
          otherwise in connection with any provision of these Terms), even if
          the Company or any supplier has been advised of the possibility of
          such damages and even if the remedy fails of its essential purpose.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Some states do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which
          means that some of the above limitations may not apply. In these
          states, each party&#39;s liability will be limited to the greatest
          extent permitted by law.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">
          &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; DISCLAIMER
        </span>
      </h3>
      <p className="c0">
        <span className="c1">
          The website is provided to You &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law, the
          Company, on its own behalf and on behalf of the Prime Contractor, and
          its Affiliates and their respective licensors and service providers,
          expressly disclaims all warranties, whether express, implied,
          statutory or otherwise, with respect to the website, including all
          implied warranties of merchantability, fitness for a particular
          purpose, title and non-infringement, and warranties that may arise out
          of course of dealing, course of performance, usage or trade practice.
          Without limitation to the foregoing, the Company and the Prime
          Contractor provides no warranty or undertaking, and makes no
          representation of any kind that the website will meet your
          requirements, achieve any intended results, be compatible or work with
          any other software, applications, systems or services, operate without
          interruption, meet any performance or reliability standards or be
          error free or that any errors or defects can or will be corrected.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Without limiting the foregoing, neither the Company, the Prime
          Contractor, nor any of the Company&#39;s providers makes any
          representation or warranty of any kind, express or implied: (i) as to
          the operation or availability of the website, or the information,
          content, and materials or products included thereon; (ii) that the
          Service will be uninterrupted or error-free; (iii) as to the accuracy,
          reliability, or currency of any information or content provided
          through the website; or (iv) that the website, its servers, the
          content, or e-mails sent from or on behalf of the Company are free of
          viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a
          consumer, so some or all of the above exclusions and limitations may
          not apply to you. But in such a case the exclusions and limitations
          set forth in this section shall be applied to the greatest extent
          enforceable under applicable law.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Governing Law</span>
      </h3>
      <p className="c0">
        <span className="c1">
          The laws of the State of California, excluding its conflicts of law
          rules, shall govern these Terms and Your use of the website. Your use
          of the Application may also be subject to other local, state,
          national, or international laws.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Disputes Resolution</span>
      </h3>
      <p className="c0">
        <span className="c1">
          If you have any concern or dispute about the website, you agree to
          first try to resolve the dispute informally by contacting the Company.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">For European Union (EU) Users</span>
      </h3>
      <p className="c0">
        <span className="c1">
          If you are a European Union consumer, you will benefit from any
          mandatory provisions of the law of the country in which you are
          resident in.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">United States Legal Compliance</span>
      </h3>
      <p className="c0">
        <span className="c1">
          You represent and warrant that (i) you are not located in a country
          that is subject to a United States government embargo, or that has
          been designated by the United States government as a &ldquo;terrorist
          supporting&rdquo; country, and (ii) you are not listed on any United
          States government list of prohibited or restricted parties.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Severability</span>
      </h3>
      <p className="c0">
        <span className="c1">
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Waiver</span>
      </h3>
      <p className="c0">
        <span className="c1">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under these Terms shall not
          effect a party&#39;s ability to exercise such right or require such
          performance at any time thereafter nor shall be the waiver of a breach
          constitute a waiver of any subsequent breach.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Translation Interpretation</span>
      </h3>
      <p className="c0">
        <span className="c1">
          These Terms and Conditions may have been translated if we have made
          them available to you on our website.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          You agree that the original English text shall prevail in the case of
          a dispute.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Changes to These Terms and Conditions</span>
      </h3>
      <p className="c0">
        <span className="c1">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will make
          reasonable efforts to provide at least 30 days&#39; notice prior to
          any new terms taking effect. What constitutes a material change will
          be determined at our sole discretion.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          By continuing to access or use our website after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, in whole or in part, please stop using
          the website.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Contact Us</span>
      </h3>
      <p className="c0">
        <span className="c1">
          If you have any questions about this Privacy Policy, You can{" "}
        </span>
        <span className="c16">
          <a className="c19" href="mailto:tjensen@icatalysts.net">
            contact us
          </a>
        </span>
        <span className="c1">.</span>
      </p>
      <p className="c8 c13">
        <span className="c12 c20"></span>
      </p>
    </article>
  );
}

export default UserPolicy;
