import React from "react";

function AboutUs() {
  return (
    <article className="policy">
      <h1>About </h1>
      <p className="c10">
        <span className="c1">
          This Innovation Network Analyzer (the Analyzer) is a web tool based on
          social network science developed by ICatalysts for the California
          Energy Commission (CEC) to analyze innovation networks.* &nbsp;This
          tool was developed under a prime contract held by Industrial
          Economics, Inc (IEC). &nbsp;IEC has made invaluable contributions to
          the requirements for the Analyzer.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          The CEC prioritized social network analysis (SNA) as a method to
          analyze the Commission&rsquo;s investments in innovation because SNA
          is an accepted method to evaluate knowledge sharing, professional
          network development, and increased connections between technology
          developers, funders, customers, investors and experts.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          SNA involves mapping and characterizing a network, which is defined as
          relationships between people, organizations, or market segments. SNA
          maps take the form of a discrete graph, which can be analyzed using
          graph theory.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          At present, this version of the Analyzer relies only on data from the
          Empower Innovation Network (EIN), an online innovation collaborative
          platform funded by the CEC for individuals and organizations to find
          funding, services and to message with customers and others.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          The Analyzer can provide insight into size and structure of a network
          (e.g., a Regional Energy Innovation Cluster (REIC)) and the relative
          importance of actors and connections within the network. These
          insights could create actionable responses to disparities in network
          structure such as redundancy or scarcity of certain REIC resources
          (e.g., staff, mentors, advisors).
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          This current version (e.g., beta) of the Analyzer only analyzes
          connections between people, not between people and organizations, or
          between organizations, or within or between markets or geographic
          regions. The Analyzer could be modified to address these topics and
          also to gain insight on optimal pathways for disseminating ideas,
          knowledge, information, and/or resources by leveraging network
          analysis.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          The Analyzer is limited by and dependent on data sources especially
          messaging data. The Analyzer has data for individuals including name,
          expertise and technology developed or sought, and also meta data on
          messaging (i.e., sender, recipient(s), date, response) if the
          individual has sent and/or received a message via the platform (i.e.,
          EIN).
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          A key limiting factor is the number of EIN members who message each
          other through the platform. At present, less than 5% of members have
          sent or received messages. Because connections between people are
          defined by whether they have sent and/or exchanged messages, the
          network structure and display are therefore limited by messaging or
          the lack of messaging. &nbsp;
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          This situation will be mitigated when more members use platform
          messaging and possibly if the Analyzer can access data from other EIN
          data and sources to understand affinity and relationships better
          (e.g., team members, membership in an accelerator, people who attend
          events, possibly other messaging data that the REICs may have (e.g.,
          Slack messaging data), and contractual relationships between
          organizations).
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          While the Analyzer provides some insight even with a small group of
          people, clearly the results will be more complete as more data are
          available. It should be noted that a complete picture of the network
          will likely never be visible because individuals use many means to
          communicate and interact.
        </span>
      </p>
      <h3 className="c10">
        <span className="c7">Uses of the Analyzer</span>
      </h3>
      <p className="c10">
        <span className="c1">
          The Analyzer&rsquo;s overriding goal is to offer a user-friendly,
          dynamic tool that can characterize a network including its
          subnetworks. Presently, when data is loaded into the Analyzer, the
          network will be comprised only by data from members of the EIN.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          Subnetworks are the EIN members that represent members of EIN&rsquo;s
          five major accelerator and seed funding programs that the Commission
          has funded. These include four REICs &ndash; Cyclotron Road in the San
          Francisco Bay Area, BlueTechValley located in California&rsquo;s
          Central Valley, LACI in the Los Angeles region, and the Southern
          California Energy Innovation Network &ndash; and New Energy
          Nexus&rsquo;s seed fund known as CalSEED.
        </span>
      </p>
      <h3 className="c10">
        <i>Using the Analyzer</i>
      </h3>
      <p className="c10">
        <span className="c1">
          Users of the Analyzer may filter by network and role type and search
          by a person&rsquo;s name and tags (e.g., the system includes 61 tags
          derived from people&rsquo;s stated technology sector in their EIN
          profiles (e.g., batteries, electric vehicles, HVAC, Internet of
          Things)).
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          Users can view metrics in the table below each graph (such as
          centrality, or the most important people/actors) and view insight on
          what would fragment or break a network and on what new connections the
          tool predicts.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          Within this context, the primary use cases for the Analyzer are to:
        </span>
      </p>
      <ul className="c2 lst-kix_list_1-0 start">
        <li className="c5">
          <span className="c1">
            Access statistics of types of members by the entire network or a
            subnetwork(s) (e.g., number of entrepreneurs).
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Evaluate the strengths and weaknesses of the overall innovation
            network or subnetworks.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Identify the most central nodes (i.e., people/actors) as evidenced
            by the network data. Centrality is an indication of importance.
            Please see definitions.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Get insight on the fragility of the network or subnetworks &ndash;
            for example, analyzing the projected impact of specific actors
            leaving the network (e.g., accelerator staff leaving their REIC).
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            See new possible valuable connections for actors based on the
            tool&rsquo;s link prediction algorithm. This insight could lead to
            recommending new connections to people.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Make general comparisons between subnetworks.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            Evaluate the Empower Innovation Network itself.
          </span>
        </li>
      </ul>
      <p className="c8 c13">
        <span className="c1"></span>
      </p>
      <p className="c8">
        <span className="c1">Please Note *</span>
      </p>
      <p className="c10">
        <span className="c1">
          The Analyzer was made by ICatalysts with State of California support
          under California Energy Commission Agreement Number 300-17-004 under a
          prime contract held by Industrial Economics, Inc (IEC). &nbsp;IEC has
          made invaluable contributions to the requirements for the Analyzer.
        </span>
      </p>
      <p className="c8">
        <span className="c1">
          The Energy Commission has certain rights to the Analyzer under the
          Agency&rsquo;s standard contact terms and conditions. &nbsp; These
          rights include a non-exclusive, transferable, irrevocable,
          royalty-free, worldwide, perpetual license to use, publish, translate,
          modify, and reproduce ICatalysts&rsquo; intellectual property for
          governmental purposes. &nbsp;State law states that the license is
          transferable only to load-serving entities (e.g., Investor Owned
          Utilities) for the purpose described above and below.
        </span>
      </p>
    </article>
  );
}

export default AboutUs;
