// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:b830b66b-b8c7-4b3b-8355-74639d5a0a66",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_xrt4daQzC",
  aws_user_pools_web_client_id: "3pjd29so5ke1on55m4apdqrhsn",
  oauth: {
    domain: "circlebuild7d8491b7-7d8491b7-dev.auth.us-east-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL,
    redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;
