import React, { useState } from "react";
import { Link } from "react-router-dom";

function Profile() {
  const [imageURL, setImageURL] = useState(
    process.env.PUBLIC_URL + "/profile-placeholder-icon.png"
  );

  function handleProfileSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    // make HTTP call to API where we can save form information
    // const formData = new FormData(e.target);
    // fetch("https://example.com/profile/", {
    //   method: "POST",
    //   body: formData
    // });
  }
  function readImage(e) {
    if (e.target.files && e.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        setImageURL(e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }
  return (
    <div>
      <ProfileHeader />
      <article className="profile">
        <form className="profile" onSubmit={handleProfileSubmit}>
          <h1 className="profile">Create your Profile</h1>
          <fieldset className="profile__photo-upload__fieldset">
            <label className="profile-photo-upload">
              Upload Photo
              <img src={imageURL} alt="Profile icon" width="200" />
              <input
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={readImage}
                style={{ display: "none" }}
              ></input>
            </label>
          </fieldset>
          <fieldset className="profile__text-fields__fieldset">
            <label htmlFor="name">Name (First and Last)</label>
            <input
              type="text"
              size="30"
              name="name"
              placeholder="Johnny Appleseed"
            ></input>
            <label htmlFor="work">Current Work</label>
            <input
              type="text"
              size="30"
              name="work"
              placeholder="Title"
            ></input>
            <input
              type="text"
              size="30"
              name="work"
              placeholder="Company"
            ></input>
            <label htmlFor="location">Location</label>
            <input
              type="text"
              size="30"
              name="location"
              placeholder="United States"
            ></input>
          </fieldset>
          <Link to="/dashboard" className="profile-submit__link">
            <button className="profile-submit__button" type="submit">
              Save and Continue
            </button>
          </Link>
        </form>
      </article>
    </div>
  );
}
function ProfileHeader() {
  return (
    <header className="App-header dashboard">
      <nav>
        <ul>
          <li>
            <Link to="/"> Innovation Network Analyzer </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Profile;
