import React from "react";
import { Link } from "react-router-dom";
function HomepageHeader() {
  return (
    <header className="App-header homepage">
      <nav>
        <ul>
          <li>
            <Link to="/"> Innovation Network Analyzer </Link>
          </li>
        </ul>
      </nav>
      <nav>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <a
              className="login"
              href={process.env.REACT_APP_AWS_COGNITO_LOGIN_URL}
            >
              Log In
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default HomepageHeader;
