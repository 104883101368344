import React from "react";

function TeamMembersList(props) {
  const teamMembers = [
    { name: "Roxanne", email: "blank@gmail.com" },
    { name: "Julie", email: "blank@gmail.com" },
  ];
  //   const [teamMembers, setTeamMembers] = useState()
  //   useEffect(() => {
  //     async function fetchTeamMembers() {
  //       const promise = await fetch("/api/get_team_members/" + props.team);
  //       const response = await promise.json();
  //       setTeamMembers(response);

  //     }
  //     fetchTeamMembers();
  //   });

  return (
    <ul className="TeamMembersList">
      {teamMembers.map(({ name, email }) => {
        return (
          <li key={name}>
            <a href={`mailto:${email}`} target="_blank">
              {name}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default TeamMembersList;
