import React, { useState } from "react";
import { Link } from "react-router-dom";
import Graph from "../Graph";
import Graph_1 from "../Graph_1";
import Graph_2 from "../Graph_2";
import TeamsList from "./TeamsList";

function Dashboard({ handleLogout }) {
  const syncs = [{ when: "11/4/20", success: true }];

  return (
    <div>
      <DashboardHeader handleLogout={handleLogout} />
      <article className="dashboard">
        <section className="dashboard__left-column">
          <TeamsList />
          <Link to="/definitions">
            <h3>Definitions</h3>
          </Link>

          <h3><u>Instructions</u></h3>
          <ul>
            <li><u><b>Pan</b></u> - Click and drag on whitespace</li>
            <li><u><b>Zoom</b></u> - Scrollwheel</li>
            <li><u><b>See Node Details</b></u> - Hover over node</li>
            <li><u><b>Persist Node Details</b></u> - Click on node</li>
            <li><u><b>Hide Node Details</b></u> - Click on node again</li>
          </ul>
          <ul className="sync-status-container dashboard__container">
            {syncs.map((sync, idx) => (
              <li className="sync-status-item" key={idx}>
                Last Update: {sync.when}
              </li>
            ))}
          </ul>
        </section>
        <CenterColumn />
      </article>
    </div>
  );
}

function DashboardHeader({ handleLogout }) {
  const [openDropdown, setOpenDropdown] = useState(false);
  function handleOpenDropdown() {
    setOpenDropdown(!openDropdown);
  }
  return (
    <header className="App-header dashboard">
      <nav>
        <ul>
          <li>
            <Link to="/"> Innovation Network Analyzer </Link>
          </li>
        </ul>
      </nav>

      <div className="dropdown">
        <img
          onClick={handleOpenDropdown}
          width="50"
          src={process.env.PUBLIC_URL + "/profile-icon.png"}
          alt="Profile"
        />
        <div
          id="myDropdown"
          className={`dropdown-content ${openDropdown ? "show" : ""}`}
        >
          <Link to="/profile">Create Profile</Link>
          <a href="/#" onClick={handleLogout}>
            Logout
          </a>
        </div>
      </div>
    </header>
  );
}

function CenterColumn() {
  const [userView, setUserView] = useState(true);
  const [orgView, setOrgView] = useState(false);
  //const [filter, setFilter] = useState("Location");
  /*const filterOptions = [
    "Location",
    "Company",
    "Tags",
    "Last Contacted",
    "Title",
    "Industry",
  ];*/
  /*function handleFilterSelection(e) {
    setFilter(e.target.value);
  }*/
  return (
    <section className="dashboard__center-column">
      <div className="visualize-header-container">
      <span className= {userView ? "left" : orgView ? "right" : "middle"}></span>
      <h2
        onClick={() => {
          setUserView(true);
          setOrgView(false);
        }}
        className = {userView ? "is-selected left" : "left"}
      >
        People View
      </h2>
      <h2
        onClick={() => {
          setUserView(false);
          setOrgView(false);
        }}
        className = {userView ? "middle" : orgView ? "middle" : "is-selected middle"}
      >
        Awards View
      </h2>
      <h2
        onClick={() => {
          setUserView(false);
          setOrgView(true);
        }}
        className = {orgView ? "is-selected right" : "right"}
      >
        Org View
      </h2>
      </div>
        {userView ? <Graph /> : orgView ? <Graph_1 /> : <Graph_2 />}
    </section>
  );
}

export { Dashboard as default, DashboardHeader };
