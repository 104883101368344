import React, { useState } from "react";
import HomepageHeader from "./HomepageHeader";

function Homepage() {
  return (
    <div>
      <HomepageHeader />
      <HomepageFirstSection />
      <HomepageSecondSection />
    </div>
  );
}
function HomepageFirstSection() {
  return (
    <article className="homepage__first-section">
      <h1 className="App-headline">
        Analyze your network to strengthen your capacity to achieve your goals
      </h1>
      <section className="homepage__first-section">
        <h2>
          Visualize network patterns and activity to diagnose strengths and
          weaknesses.
        </h2>
      </section>
    </article>
  );
}

function HomepageSecondSection() {
  const headlines = [
    "Analyze network interaction.",
    "Strengthen community networks.",
    "Identify specialists in a technology sector.",
  ];
  const [indexSelected, setIndexSelected] = useState(0);
  const imagePaths = [
    "home_graphic1.png",
    "home_graphic2.png",
    "home_graphic3.png"
  ];
  const buttonTexts = [
    "See connections",
    "View key players",
    "Search for experts"
  ];

  function handleButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    setIndexSelected(Number(e.target.value));

    return;
  }

  return (
    <article className="homepage__second-section">
      <h2 className="homepage__second-section">{headlines[indexSelected]}</h2>
      <section className="homepage__second-section">
        <img
          alt={imagePaths[indexSelected]}
          src={process.env.PUBLIC_URL + imagePaths[indexSelected]}
          width="749"
          height="501"
        />
        <ul className="homepage__second-section">
          {buttonTexts.map((text, idx) => {
            return (
              <li key={idx}>
                <button
                  onClick={handleButtonClick}
                  value={idx}
                  className={indexSelected === idx ? "is-selected" : ""}
                >
                  {text}
                </button>
              </li>
            );
          })}
        </ul>
      </section>
    </article>
  );
}
export default Homepage;
