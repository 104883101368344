import React, { useState } from "react";
import TeamMembersList from "./TeamMembersList";

function TeamsList() {
  const [showAllTeams, setShowAllTeams] = useState(false);
  const [teamSelected, setTeamSelected] = useState("");

  const teams = ["Team Name 1", "Team Name 2", "Team Name 3", "Team Name 4"];

  function handleShowMoreTeams() {
    setShowAllTeams(!showAllTeams);
  }
  return (
    <div>
      <h2>My Teams</h2>
      <ul className="dashboard__left-column-group-list">
        {teams.map((team, idx) => {
          const hide = idx > 2 && !showAllTeams;
          let style = {};
          if (hide) {
            style = { display: "none" };
          }
          return (
            <li
              className="TeamsList__team"
              key={team}
              style={style}
              onClick={setTeamSelected.bind(this, team)}
            >
              <div className="TeamsList__team-name">
                <img
                  src={process.env.PUBLIC_URL + "/group-icon.png"}
                  alt="group icon"
                  height="30"
                />
                <span className="overflow-ellipsis">{team}</span>
              </div>
              {teamSelected === team && <TeamMembersList team={team} />}
            </li>
          );
        })}
      </ul>
      <button className="show-more-button" onClick={handleShowMoreTeams}>
        <div className={showAllTeams ? "arrow-up" : "arrow-down"}></div>
        See {showAllTeams ? "less" : "more"}&#x2026;
      </button>
    </div>
  );
}

export default TeamsList;
