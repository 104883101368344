import React from "react";

function PrivacyPolicy() {
  return (
    <article className="policy">
      <h1 className="c0">Privacy Policy</h1>
      <p className="c0">
        <span className="c1">Last updated: June 10, 2020</span>
      </p>
      <p className="c0">
        <span className="c1">
          This Privacy Policy describes ICatalysts&rsquo; Privacy policies and
          procedures on the collection, use and disclosure of a user&rsquo;s
          information and information available from the Innovation Network
          Analyzer (Analyzer) when the Service is used .
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We use a user&rsquo;s personal data to provide and improve the
          Analyzer. &nbsp;By using the Analyzer, as a user you agree to the
          collection and use of information in accordance with this Privacy
          Policy. Also, refer to the Terms of Use page on this website.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Interpretation</span>
      </h3>
      <p className="c0">
        <span className="c1">
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Definitions</span>
      </h3>
      <p className="c0">
        <span className="c1">For the purposes of this Privacy Policy:</span>
      </p>
      <p className="c0">
        <span className="c1">
          &ldquo;You&rdquo; means the individual or user accessing or using the
          Analyzer, or the company, or other legal entity on behalf of which
          such individual is accessing or using the Analyzer, as applicable.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          &ldquo;We&rdquo;/&ldquo;our&rdquo;/&rdquo;Us&rdquo; means the
          ICatalysts which is located at 122 Redwood Road, San Anselmo,
          California, 94960, USA.
        </span>
      </p>
      <p className="c0">
        <span className="c1">Users are organized into four groups:</span>
      </p>
      <ol className="c2 lst-kix_list_2-0 start" start="1">
        <li className="c5">
          <span className="c1">
            EPIC/California Energy Commission (CEC) analysts and contractors.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Accelerator managers in CEC-funded programs (e.g., Regional Energy
            Innovation Cluster (REIC) and CalSEED Managers including
            operations&nbsp;staff).
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Empower Innovation Network Directors, Administrators and Service
            Providers.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            Innovation Network Analyzer Software Developers, Analysts, Technical
            Support and Administrative staff.
          </span>
        </li>
      </ol>
      <ul className="c2 lst-kix_list_3-0 start">
        <li className="c5">
          <span className="c1">
            &ldquo;Empower Innovation Member&rdquo; (&ldquo;EIM&rdquo;) means
            the individual whose data is analyzed by this tool.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            &ldquo;Account&rdquo; means a unique account created for You to
            access this Analyzer or parts of it.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            &ldquo;Website&rdquo; refers to the Innovation Network Analyzer,
            accessible from https://networkanalyzer.icatalysts.net/
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            &ldquo;Country&rdquo; refers to: United States
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            &ldquo;Service Provider&rdquo; means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service/Analyzer, to provide the Service/Analyzer on
            behalf of the Company, to perform services related to the Analyzer
            or to assist the Company in analyzing how the tool is used.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            &ldquo;Usage Data&rdquo; refers to data collected automatically,
            either generated by the use of the Analyzer or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </span>
        </li>
      </ul>
      <h3 className="c0">
        <span className="c7">Types of Data Collected</span>
      </h3>
      <h4 className="c0">
        <span className="c12 c6">Personal Data</span>
      </h4>
      <p className="c0">
        <span className="c1">
          While using the Analyzer, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally, identifiable information may include, but is
          not limited to:
        </span>
      </p>
      <ul className="c2 lst-kix_list_4-0 start">
        <li className="c5">
          <span className="c1">Email address</span>
        </li>
        <li className="c4">
          <span className="c1">First name and last name</span>
        </li>
        <li className="c4">
          <span className="c1">Location such as a City</span>
        </li>
        <li className="c3">
          <span className="c1">Usage Data</span>
        </li>
      </ul>
      <h4 className="c0">
        <span className="c12 c6">Usage Data</span>
      </h4>
      <p className="c0">
        <span className="c1">
          Usage Data is collected automatically when using the Analyzer.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Usage Data may include information such as a User&rsquo;s Device&#39;s
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Analyzer that users visit, the time and date
          of visits, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          When you as a user access the Analyzer by or through a mobile device,
          We may collect certain information automatically, including, but not
          limited to, the type of mobile device you use, your mobile device
          unique ID, the IP address of your mobile device, your mobile operating
          system, the type of mobile Internet browser you use, unique device
          identifiers and other diagnostic data.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We may also collect information that your browser sends whenever you
          visit this website or when you access the website by or through a
          mobile device.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Types and Uses of Data</span>
      </h3>
      <p className="c0">
        <span className="c1">The service uses two types of data:</span>
      </p>
      <ol className="c2 lst-kix_list_5-0 start" start="1">
        <li className="c5">
          <span className="c1">
            Data from Empower Innovation (www.empowerinnovation.net), and
          </span>
        </li>
        <li className="c3">
          <span className="c1">Personal data from users of this Service.</span>
        </li>
      </ol>
      <p className="c0">
        <span className="c1">
          Data obtained from Empower Innovation is data on members and
          organizations that members may be affiliated with. These data include
          names, locations, email addresses, business and technology types,
          funds raised, member affiliations, and messages between members.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          All of this data is public except for email addresses, funding stage,
          funds raised, technology level, and messages.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The Analyzer uses Empower Innovation data to analyze accelerator
          network structure, strengths and weaknesses and to produce actionable
          insight for accelerator managers, CEC and other users. Specific
          examples of analysis that the Analyzer supports include:
        </span>
      </p>
      <ul className="c2 lst-kix_list_6-0 start">
        <li className="c5">
          <span className="c1">
            Network structure and statistics such as number of members and
            member type and industry.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Network strengths including data indicating insight on the most
            important network nodes based on their &ldquo;centrality&rdquo;
            including founders, investors and accelerator managers.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            Network weaknesses including fragile components that if broken may
            weaken or break the network (e.g., lack of investors, or too few
            important nodes that may indicate that the network may overly rely
            on a few or a couple people to function).
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1">
          To provide this analysis, the Analyzer uses message data between users
          to measure connections. Message data include: sender, receiver, date,
          response, and read status.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Personal data from users of this Analyzer is used for the following
          purposes:
        </span>
      </p>
      <ul className="c2 lst-kix_list_7-0 start">
        <li className="c5">
          <span className="c1">
            To provide and maintain the website, including to monitor the usage
            of the website.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            To manage User Accounts: to manage user registration as a user of
            the website. The Personal Data you provide can give you access to
            different functionalities of the Analyzer that are available to you
            as a registered user. For example, a user may not have access to
            data on all the networks (i.e., entire network, subnetworks) or not
            have access to certain data in a network (e.g., weak points in
            certain subnetworks). This information is also important if your
            user status changes (e.g., You leave the website/service).
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            To contact you: To contact you by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application&#39;s push notifications regarding updates or
            informative communications related to the functionalities, products
            or contracted services, including the security updates, when
            necessary or reasonable for their implementation.
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            To provide you with news, updates, and general information about
            other related services and events which we offer that are similar to
            those that you already use.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            To manage your requests: To attend and manage your requests to Us.
          </span>
        </li>
      </ul>
      <p className="c0">
        <span className="c1">
          We may share your personal information in the following situations:
        </span>
      </p>
      <ul className="c2 lst-kix_list_8-0 start">
        <li className="c5">
          <span className="c1">
            With Service Providers: We may share your personal information with
            Service Providers (e.g., hosting provider -- Python Anywhere) to
            monitor and analyze the use of our website and to contact you.
          </span>
        </li>
        <li className="c3">
          <span className="c1">
            For Business transfers: We may share or transfer your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of our business to another company. &nbsp;This may be the
            case if a successor company has a contract to maintain or host this
            Analyzer.
          </span>
        </li>
      </ul>
      <p className="c9 c15">
        <span className="c1"></span>
      </p>
      <ul className="c2 lst-kix_list_8-0">
        <li className="c10 c15 c17">
          <span className="c1">
            With Affiliates: We may share your information with our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include our parent company and any other
            subsidiaries, joint venture partners or other companies that we
            control or that are under common control with Us.
          </span>
        </li>
      </ul>
      <h3 className="c0">
        <span className="c7">Retention of Data</span>
      </h3>
      <p className="c0">
        <span className="c1">
          The Service will retain your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The website will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of the website, or we are legally obligated
          to retain this data for longer time periods.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Empower Innovation data will be retained in the same manner.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Transfer of Data</span>
      </h3>
      <p className="c0">
        <span className="c1">
          Information, including Personal Data and Empower Innovation Data, is
          processed at the Company&#39;s operating offices and in any other
          places where the parties involved in the processing are located. It
          means that this information may be transferred to &mdash; and
          maintained on &mdash; computers located outside of your state,
          province, country or other governmental jurisdiction where the data
          protection laws may differ than those from your jurisdiction.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Your consent to this Privacy Policy followed by your submission of
          such information represents Your agreement to that transfer.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The Company will take all steps reasonably necessary to ensure that
          your Data and Empower Innovation Data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal
          Data or Empower Innovation Data will take place to an organization or
          a country unless there are adequate controls in place including the
          security of Your data and other personal information.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Disclosure of Data</span>
      </h3>
      <h4 className="c0">
        <span className="c6 c12">Business Transactions</span>
      </h4>
      <p className="c0">
        <span className="c1">
          If the Company is involved in a merger, acquisition or asset sale,
          your Personal Data and Empower Innovation Data may be transferred. We
          will provide notice before your Personal Data is transferred and
          becomes subject to a different Privacy Policy.
        </span>
      </p>
      <h4 className="c0">
        <span className="c12 c6">Law enforcement</span>
      </h4>
      <p className="c0">
        <span className="c1">
          Under certain circumstances, the Company may be required to disclose
          your Personal Data or Empower Innovation Data if required to do so by
          law or in response to valid requests by public authorities (e.g. a
          court or a government agency).
        </span>
      </p>
      <h4 className="c0">
        <span className="c12 c6">Other legal requirements</span>
      </h4>
      <p className="c0">
        <span className="c1">
          The Company may disclose your Personal Data or Empower Innovation Data
          in the good faith belief that such action is necessary to:
        </span>
      </p>
      <ul className="c2 lst-kix_list_9-0 start">
        <li className="c5">
          <span className="c1">Comply with a legal obligation</span>
        </li>
        <li className="c4">
          <span className="c1">
            Protect and defend the rights or property of the Company
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Prevent or investigate possible wrongdoing in connection with the
            website
          </span>
        </li>
        <li className="c4">
          <span className="c1">
            Protect the personal safety of Users of the website or the public
          </span>
        </li>
        <li className="c3">
          <span className="c1">Protect against legal liability</span>
        </li>
      </ul>
      <h3 className="c0">
        <span className="c7">Security of Data</span>
      </h3>
      <p className="c0">
        <span className="c1">
          The security of your Personal Data and Empower Innovation data is
          important to us but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure. While we
          strive to use commercially acceptable means to protect Data, We cannot
          guarantee its absolute security.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          Our website does not respond to Do Not Track (DNT) signals.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          However, some third-party websites do keep track of your browsing
          activities. If you are visiting such websites, you can set your
          preferences in your web browser to inform websites that you do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of your web browser.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The website does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If you are a parent or guardian and you are aware
          that your child has provided us with Personal Data, please contact us.
          If we become aware that we have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, we will
          take steps to remove that information from our servers.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          The website may contain links to other websites that are not operated
          by us. If you click on a third-party link, you will be directed to
          that third party&#39;s site. We strongly advise you to review the
          Privacy Policy of every site you visit.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or services.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Changes to Privacy Policy</span>
      </h3>
      <p className="c0">
        <span className="c1">
          We may update our Privacy Policy from time to time.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We will notify you of any changes by posting the new Privacy Policy on
          this page.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          We will let You know via email and/or a prominent notice on the
          website, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </span>
      </p>
      <p className="c0">
        <span className="c1">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </span>
      </p>
      <h3 className="c0">
        <span className="c7">Contact Us</span>
      </h3>
      <p className="c0">
        <span className="c1">
          If you have any questions about this Privacy Policy, You can{" "}
        </span>
        <span className="c16">
          <a className="c19" href="mailto:tjensen@icatalysts.net">
            contact us
          </a>
        </span>
        <span className="c1">.</span>
      </p>
      <p className="c9">
        <span className="c1"></span>
      </p>
      <p className="c9">
        <span className="c1"></span>
      </p>
      <p className="c9">
        <span className="c1"></span>
      </p>
    </article>
  );
}

export default PrivacyPolicy;
