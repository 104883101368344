import React from "react";

function Definitions() {
  return (
    <article className="policy">
      <h1>Definitions</h1>
      <p className="c3" id="h.gjdgxs">
        <span className="c1">
          Major metrics and terms used in the Analyzer are defined below. &nbsp;
          Each definition describes how the Analyzer applies this metric or
          term. &nbsp; Generally accepted definitions from the field of Social
          Network Analysis are used.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h3 className="c8">
        <span className="c18">Social Network Analysis</span>
      </h3>
      <h3 className="c6">
        <span className="c11 c4"></span>
      </h3>
      <p className="c3">
        <span className="c4">
          Social network analysis&nbsp;(SNA) is the process of investigating
          social structures through the use of&nbsp;
        </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Network_theory&amp;sa=D&amp;ust=1590202681277000"
          >
            networks
          </a>
        </span>
        <span className="c4">&nbsp;and&nbsp;</span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Graph_theory&amp;sa=D&amp;ust=1590202681278000"
          >
            graph theory
          </a>
        </span>
        <span className="c1">
          . It characterizes networked structures in terms
          of&nbsp;nodes&nbsp;(individual actors (i.e., the role of a person),
          people, or items) and the&nbsp;ties,&nbsp;edges,
          or&nbsp;links&nbsp;(relationships or interactions) that connect them.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c2">
          An&nbsp;edge&nbsp;(a connection between two elements) is drawn as a
          line connecting two nodes.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4">Examples of&nbsp;</span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Social_structure&amp;sa=D&amp;ust=1590202681279000"
          >
            social structures
          </a>
        </span>
        <span className="c4">
          &nbsp;commonly visualized through social network analysis
          include&nbsp;
        </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Social_media&amp;sa=D&amp;ust=1590202681279000"
          >
            social media networks
          </a>
        </span>
        <span className="c4">,&nbsp;information circulation, </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Weak_ties&amp;sa=D&amp;ust=1590202681279000"
          >
            friendship and acquaintance networks
          </a>
        </span>
        <span className="c4">, business networks, knowledge networks. </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Social_network_analysis&amp;sa=D&amp;ust=1590202681280000"
          >
            Learn more
          </a>
        </span>
        <span className="c1">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4">
          The network and sub networks in this Innovation Network Analyzer are
          undirected.{" "}
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://www.sciencedirect.com/topics/computer-science/undirected-network&amp;sa=D&amp;ust=1590202681280000"
          >
            Learn more
          </a>
        </span>
        <span className="c1">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c14 c19">
          An&nbsp;undirected network consists of a set of entities and a set of
          pairwise relationships among them. Each relationship signifies only
          that two entities are somehow connected (e.g., that John and Sue
          communicate), making no distinction between &lsquo;sending&rsquo; and
          &lsquo;receiving&rsquo; entities
        </span>
        <span className="c15 c14">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://www.sciencedirect.com/topics/computer-science/undirected-network&amp;sa=D&amp;ust=1590202681281000"
          >
            . Learn more
          </a>
        </span>
        <span className="c14 c19">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          For the sake of convenience, the follow sections are arranged as they
          appear within the Innovation Analyzer Tables, with definitions of each
          measure provided.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h3 className="c8">
        <span className="c4 c11">Counts</span>
      </h3>
      <h4 className="c8 c10">
        <span className="c13 c4"></span>
      </h4>
      <h4 className="c8">
        <span className="c13 c4">Number of Nodes </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The number of nodes, or users, that are present in the network.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Density </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c14">
          The percentage of links out of the largest possible number of links
          between nodes in the network. &nbsp;
        </span>
        <span className="c14 c15">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Dense_graph&amp;sa=D&amp;ust=1590202681284000"
          >
            Learn more
          </a>
        </span>
        <span className="c5">&hellip; &nbsp;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Role Counts</span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          For each role (Entrepreneur, Consultant, etc.), the number of users
          with that role in the network.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Reciprocity </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c14">
          Reciprocity represents the percentage of messages that receive a
          response.
        </span>
      </p>
      <h3 className="c6">
        <span className="c11 c4"></span>
      </h3>
      <h3 className="c8">
        <span className="c11 c4">Importance</span>
      </h3>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          All metrics in the Importance tab refer to a numerical measure of a
          node&rsquo;s &ldquo;importance&rdquo; that was derived from a trio of
          centrality metrics (see the Centrality section for more details).
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c12">
          These centrality metrics were used to give each node three measures of
          importance (one for each metric). Then, these values were scaled using{" "}
        </span>
        <span className="c15 c14">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Feature_scaling%23Rescaling_(min-max_normalization)&amp;sa=D&amp;ust=1590202681287000"
          >
            min-max normalization
          </a>
        </span>
        <span className="c4 c12">
          . Finally, they were then averaged to give each node one
          &ldquo;importance&rdquo; value.
        </span>
      </p>
      <h4 className="c8 c10">
        <span className="c13 c4"></span>
      </h4>
      <h4 className="c8">
        <span className="c13 c4">Average Importance</span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The arithmetic mean of the set of importance values in the network.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Median Importance</span>
      </h4>
      <h4 className="c8 c10">
        <span className="c13 c4"></span>
      </h4>
      <p className="c3">
        <span className="c1">
          The median (middle-value) of the set of importance values in the
          network.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Standard Deviation </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c12">
          The standard deviation of the set of importance values in the network.
          This is a measure of how much variance exists in the data, or how far
          values tend to fall from the average.{" "}
        </span>
        <span className="c15 c14">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Standard_deviation&amp;sa=D&amp;ust=1590202681290000"
          >
            Learn more
          </a>
        </span>
        <span className="c12 c14">&hellip; </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Most Central Nodes </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The nodes with the highest importance, including the users&rsquo;
          names and importance value. Note: Due to normalization, the highest
          value will always be &ldquo;1&rdquo;.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Mode </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c12">
          The value that occurs most often in the set of importance values in
          the network.
        </span>
      </p>
      <h3 className="c6">
        <span className="c11 c4"></span>
      </h3>
      <h3 className="c8">
        <span className="c11 c4">Insights</span>
      </h3>
      <p className="c3 c10">
        <span className="c12 c17"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Number of Communities</span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c12">The number of communities</span>
        <span className="c4">&nbsp;</span>
        <span className="c4 c12">
          that exist within the network. A community is de
        </span>
        <span className="c4">fined</span>
        <span className="c4 c12">
          &nbsp;as a group of nodes that are significantly mo
        </span>
        <span className="c4">
          re connected with each other than they are to other nodes.{" "}
        </span>
        <span className="c1">
          For more information, see the section on modularity.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Large Community Concentration </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The percentage of nodes that fall within the two largest communities
          present in the network. Essentially, this measures involvement by
          nodes in large communities.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Modularity </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c9">
          Modularity measures the strength of division of a network into modules
          (also called groups, clusters or communities). Networks with high
          modularity have dense connections between the nodes within modules but
          sparse connections between nodes in different modules.&nbsp;{" "}
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Modularity_(networks)&amp;sa=D&amp;ust=1590202681295000"
          >
            Learn more
          </a>
        </span>
        <span className="c4 c9">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Total Removal Set </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The set of nodes (given by name) which must be removed to completely
          disconnect the network. That is, the resulting network after the
          removal of this set will have no links.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Total Removal Percentage </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          The percentage of nodes from the network that are in the Total Removal
          Set.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Average Removals Needed</span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c1">
          For an average pair of nodes, how many nodes must be removed such that
          there is no path that connects them.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Predicted Links</span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4 c12">
          Based on the structure of the network, these are the pairs of nodes
          that are not currently connected but are most likely to become
          connected in the future.{" "}
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://medium.com/@andresespinosapc/the-link-prediction-problem-for-social-networks-362e8355e555&amp;sa=D&amp;ust=1590202681299000"
          >
            Learn more
          </a>
        </span>
        <span className="c4 c20">&hellip; &nbsp; </span>
      </p>
      <h3 className="c6">
        <span className="c11 c4"></span>
      </h3>
      <h3 className="c8">
        <span className="c11 c4">Centrality</span>
      </h3>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4">In&nbsp;</span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Graph_theory&amp;sa=D&amp;ust=1590202681300000"
          >
            graph theory
          </a>
        </span>
        <span className="c4">&nbsp;and&nbsp;</span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Network_theory&amp;sa=D&amp;ust=1590202681300000"
          >
            network analysis
          </a>
        </span>
        <span className="c4">
          , indicators of&nbsp;centrality&nbsp;identify the most structurally
          &nbsp;important&nbsp;
        </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Vertex_(graph_theory)&amp;sa=D&amp;ust=1590202681301000"
          >
            vertices
          </a>
        </span>
        <span className="c4">
          &nbsp;within a graph including identifying the most influential
          person(s) in a&nbsp;
        </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Social_network&amp;sa=D&amp;ust=1590202681301000"
          >
            social network
          </a>
        </span>
        <span className="c4">
          . There are several ways of measuring centrality. We apply three
          commonly used centrality measures.&nbsp;{" "}
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Centrality&amp;sa=D&amp;ust=1590202681301000"
          >
            Learn more
          </a>
        </span>
        <span className="c1">&hellip; </span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Degree Centrality </span>
      </h4>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <p className="c3">
        <span className="c4">
          Historically first and conceptually simplest is&nbsp;degree
          centrality, is defined as the number of links incident upon a node
          (i.e., the number of ties that a node has). &nbsp;
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Centrality%23Degree_centrality&amp;sa=D&amp;ust=1590202681303000"
          >
            Learn more
          </a>
        </span>
        <span className="c1">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c1"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c4">Closeness Centrality </span>
      </h4>
      <h4 className="c8 c10">
        <span className="c4 c13"></span>
      </h4>
      <p className="c3">
        <span className="c4">In </span>
        <span className="c16 c4">the </span>
        <span className="c4 c16">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Graph_(discrete_mathematics)&amp;sa=D&amp;ust=1590202681304000"
          >
            graph
          </a>
        </span>
        <span className="c4">, the&nbsp;</span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Normalization_(statistics)&amp;sa=D&amp;ust=1590202681304000"
          >
            normalized
          </a>
        </span>
        <span className="c4">
          &nbsp;closeness centrality&nbsp;(or&nbsp;closeness) of a node is the
          average length of the&nbsp;
        </span>
        <span className="c16 c4">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Shortest_path_problem&amp;sa=D&amp;ust=1590202681305000"
          >
            shortest path
          </a>
        </span>
        <span className="c4">
          &nbsp;between the node and all other nodes in the graph. Thus, the
          more central a node is, the closer it is to all other nodes. &nbsp;
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Centrality%23Closeness_centrality&amp;sa=D&amp;ust=1590202681305000"
          >
            Learn more
          </a>
        </span>
        <span className="c4">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <h4 className="c8">
        <span className="c13 c14">Betweenness Centrality </span>
      </h4>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <p className="c3">
        <span className="c4">
          Betweenness centrality quantifies the number of times a node acts as a
          bridge along the shortest path between two other nodes. &nbsp;
        </span>
        <span className="c0">
          <a
            className="c7"
            href="https://www.google.com/url?q=https://en.wikipedia.org/wiki/Centrality%23Betweenness_centrality&amp;sa=D&amp;ust=1590202681306000"
          >
            Learn more
          </a>
        </span>
        <span className="c1">&hellip;</span>
      </p>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <h3 className="c8">
        <span className="c11 c14">Network Development </span>
      </h3>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <p className="c3">
        <span className="c5">
          The number of connections relative to node number from 1000 nodes
          (baseline) to other node milestones that are or will be reached (e.g.,
          1500, 2000, 3000). &nbsp;A Network Development page will be added to
          the Analyzer after the 1,500-node number is reached.
        </span>
      </p>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <h3 className="c8">
        <span className="c11 c14">Strength of Ties </span>
      </h3>
      <p className="c3 c10">
        <span className="c5"></span>
      </p>
      <p className="c3">
        <span className="c5">
          Indicated by the number of exchanged messages and the percentage of
          messages that receive a response. &nbsp;This is evidenced by an
          increase in the graph line weight between nodes.
        </span>
      </p>
    </article>
  );
}

export default Definitions;
