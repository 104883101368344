import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom"; // https://reacttraining.com/react-router/web
import "./App.css";
import Homepage from "./Homepage";
import GenericHeader from "./GenericHeader";
import Dashboard, { DashboardHeader } from "./dashboard/Dashboard";
import Profile from "./Profile";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import UserPolicy from "./policies/UserPolicy";
import AboutUs from "./policies/AboutUs";
import Definitions from "./Definitions";

import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = "DEBUG";

function App() {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(localStorage.getItem("usernameFromStorage"));
  const ref_checkIfMounted = useRef(null);

  useEffect(() => {
    if (!ref_checkIfMounted) {
      return;
    }

    async function getCurrentSession() {
      try {
        const usernameFromStorage = localStorage.getItem("username");
        if (user && user.username === usernameFromStorage) {
          return;
        }
        console.log("calling AWS");
        const authUser = await Auth.currentAuthenticatedUser();

        console.log("User", authUser);
        setUser(authUser);
        localStorage.setItem("username", authUser.username);
        history.push("/dashboard");
      } catch (e) {
        console.log("User is not authenticated ", e);
        localStorage.removeItem("username");
      }
    }
    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn_failure") {
        // show sign in window
        window.location = process.env.REACT_APP_AWS_COGNITO_LOGIN_URL;
      }
      if (data && data.username) {
        console.log(data);
        localStorage.setItem("username", data.username);
        setUser(data);
        history.push("/dashboard");
      }
    });
    getCurrentSession();
  }, [location, user, history]);

  async function handleLogout() {
    const currentUser = await Auth.userPool.getCurrentUser();
    await currentUser.signOut();
    localStorage.removeItem("username");
    setUser(null);
    history.push("/");
  }
  window.scrollTo(0, 0);

  return (
    <div className="App" ref={ref_checkIfMounted}>
      <main>
        <Switch>
          <Route exact path="/">
            {user ? <Redirect to="/dashboard" /> : <Homepage />}
          </Route>
          <Route path="/dashboard">
            {user ? (
              <Dashboard handleLogout={handleLogout} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/privacypolicy">
            <GenericHeader />
            <PrivacyPolicy />
          </Route>
          <Route path="/userpolicy">
            <GenericHeader />
            <UserPolicy />
          </Route>
          <Route path="/contact">
            <GenericHeader />
            <article className="policy">
              <h1>Contact Us</h1>
              <p>
                If you have questions about the Innovation Network Analyzer,
                would like to discuss this tool, or would like guidance on how
                to benefit from using this tool, please
                <a href="mailto:tjensen@icatalysts.net"> contact</a> Tom Jensen,
                Managing Director of ICatalysts.
              </p>
            </article>
          </Route>
          <Route path="/about">
            <GenericHeader />
            <AboutUs />
          </Route>
          <Route path="/definitions">
            <DashboardHeader handleLogout={handleLogout} />
            <Definitions />
          </Route>
          <Route>
            <GenericHeader />
            <article className="policy">
              <h1>This page does not exist.</h1>
            </article>
          </Route>
        </Switch>
      </main>
      <footer>
        <ul>
          <li>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/userpolicy">User Policy</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <h3>Powered by</h3>
          </li>
          <li>
            <img
              src={process.env.PUBLIC_URL + "/icatalyst.png"}
              alt="icatalist icon"
              id="footer-icatalyst-logo"
              width="150"
            />
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
